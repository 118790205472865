import { Status, TokenRequestorType, RequestorType } from '@/helpers/constants'
import { DateTime } from 'luxon'

const utils = {
  randomString,
  getTokenType,
  getTokenFormatType,
  getRequestorType,
  getStatusName,
  getStatusBadge,
  getTokenRequestorName,
  ccFormat,
  dateFormat,
  toDateTimeObj,
  filterToDateTimeObj,
  snakeToCamel,
  capitalize,
  getStatusOption,
  getTaskStatusOption,
  getSettingNameOption,
  getPanStatusOption,
  getTokenEventOption,
  getCardEventOption,
  getCompanyTypeOption,
  getSeverityBadge,
  getSeverityName,
  getTaskStatusName,
  getHostType,
  getHostTypeOption,
  getUserType,
  getUserTypeOption,
  numberWithCommas,
  getTestCaseColor,
  getTestCaseStatus,
  getCardSchemeLabel,
  getTokenStatusBadge,
  getTokenStatusName,
  getApiKeyTypeName,
  getTokenRequestorTypeOption,
  checkValueIsTrue
}

function snakeToCamel (str) {
  return str.replace(/([-_]\w)/g, g => g[1].toUpperCase())
}

function randomString (length) {
  const chars = '0123456789abcdefghijklmnpqrstuvwxyzABCDEFGHIJKLMNPQRSTUVWXYZ'
  var result = ''
  for (var i = length; i > 0; --i) {
    result += chars[Math.floor(Math.random() * chars.length)]
  }
  return result
}

function getCompanyTypeOption () {
  return [
    { label: 'Sole proprietorship', value: 'Sole proprietorship' },
    { label: 'Partnership', value: 'Partnership' },
    { label: 'Limited Liability Partnership (LLP)', value: 'Limited Liability Partnership (LLP)' },
    { label: 'Private Limited Company (Sdn. Bhd.)', value: 'Private Limited Company (Sdn. Bhd.)' },
    { label: 'Public Limited Company (Bhd)', value: 'Public Limited Company (Bhd)' }
  ]
}

function getStatusOption () {
  return [
    { label: 'Active', value: Status.ACTIVE },
    { label: 'Suspended', value: Status.SUSPENDED }
  ]
}

function getPanStatusOption () {
  return [
    { label: 'Active', value: Status.ACTIVE },
    { label: 'Suspended', value: Status.SUSPENDED },
    { label: 'Expired', value: Status.EXPIRED }
  ]
}

function getTaskStatusOption () {
  return [
    { label: 'Pending', value: '00' },
    { label: 'Approved', value: '01' },
    { label: 'Rejected', value: '02' },
    { label: 'Withdrawn', value: '03' },
    { label: 'Expired', value: '04' }
  ]
}

function getSettingNameOption () {
  return [
    { label: 'Token Requestor', value: 'TR' },
    { label: 'Issuer', value: 'Issuer' },
    { label: 'System', value: 'System' },
    { label: 'Token', value: 'Token' },
    { label: 'Card', value: 'Card' },
    { label: 'BIN', value: 'BIN' },
    { label: 'User', value: 'User' },
    { label: 'Role', value: 'Role' }
  ]
}

function getTokenEventOption () {
  return [
    { label: 'PROVISION', value: 'PROVISION' },
    { label: 'TOKEN_DATA', value: 'TOKEN_DATA' },
    { label: 'DE_TOKEN', value: 'DE_TOKEN' },
    { label: 'TOKEN_VERIFY', value: 'TOKEN_VERIFY' },
    { label: 'TOKEN_SUSPEND', value: 'TOKEN_SUSPEND' },
    { label: 'TOKEN_RESUME', value: 'TOKEN_RESUME' },
    { label: 'TOKEN_DELETE', value: 'TOKEN_DELETE' },
    { label: 'TOKEN_STATUS', value: 'TOKEN_STATUS' }
  ]
}

function getCardEventOption () {
  return [
    { label: 'CARD_METADATA', value: 'CARD_METADATA' },
    { label: 'CARD_SUSPEND', value: 'CARD_SUSPEND' },
    { label: 'CARD_RESUME', value: 'CARD_RESUME' },
    { label: 'CARD_DELETE', value: 'CARD_DELETE' },
    { label: 'CARD_PAN_UPDATE', value: 'CARD_PAN_UPDATE' },
    { label: 'CARD_EXPIRY_UPDATE', value: 'CARD_EXPIRY_UPDATE' }
  ]
}

function getStatusName (status) {
  return status === Status.ACTIVE ? 'Active'
    : status === Status.SUSPENDED ? 'Suspended'
      : status === Status.DELETED ? 'Deleted'
        : status === Status.EXPIRED ? 'Expired' : 'Unknown'
}

function getStatusBadge (status) {
  return status === Status.ACTIVE ? 'success'
    : status === Status.SUSPENDED ? 'warning'
      : status === Status.DELETED ? 'danger'
        : status === Status.EXPIRED ? 'warning' : 'primary'
}

function getTokenStatusName (status, cardStatus) {
  if ((status === Status.ACTIVE || status === Status.SUSPENDED) && cardStatus === Status.SUSPENDED) {
    return 'Suspended By Issuer'
  }
  return status === Status.ACTIVE ? 'Active'
    : status === Status.SUSPENDED ? 'Suspended'
      : status === Status.DELETED ? 'Deleted'
        : status === Status.EXPIRED ? 'Expired' : 'Unknown'
}

function getTokenStatusBadge (status, cardStatus) {
  if ((status === Status.ACTIVE || status === Status.SUSPENDED) && cardStatus === Status.SUSPENDED) {
    return 'warning'
  }

  return status === Status.ACTIVE ? 'success'
    : status === Status.SUSPENDED ? 'warning'
      : status === Status.DELETED ? 'danger'
        : status === Status.EXPIRED ? 'warning' : 'primary'
}

function getTokenRequestorName (type) {
  return type === TokenRequestorType.PAYMENT_GATEWAY ? 'Payment Gateway'
    : type === TokenRequestorType.MERCHANT ? 'Merchant'
      : type === TokenRequestorType.WALLET_PROVIDER ? 'Wallet Provider' : 'Unknown'
}

function getTokenType (type) {
  return type === '00' ? 'Static'
    : type === '01' ? 'Guest Checkout' : 'FRU'
}

function getTokenFormatType (type) {
  return type === '00' ? 'ISO/IEC 7812' : 'FRU'
}

function getRequestorType (type) {
  return type === RequestorType.MERCHANT ? 'Merchant'
    : type === RequestorType.ISSUER ? 'Issuer'
      : type === RequestorType.DS ? 'DS'
        : type === RequestorType.SWITCH ? 'Switch'
          : type === RequestorType.DEV_PORTAL ? 'Dev Portal'
            : type === RequestorType.SSO ? 'SSO' : 'Unknown'
}

function getSeverityBadge (severity) {
  return severity === '00' ? 'secondary'
    : severity === '01' ? 'warning'
      : severity === '02' ? 'danger' : 'danger'
}

function getSeverityName (severity) {
  return severity === '00' ? 'Low'
    : severity === '01' ? 'Medium'
      : severity === '02' ? 'High' : 'Unknown'
}

function getTaskStatusName (status) {
  return status === '00' ? 'Pending'
    : status === '01' ? 'Approved'
      : status === '02' ? 'Rejected'
        : status === '03' ? 'Withdrawn'
          : status === '04' ? 'Expired' : 'Unknown'
}

function ccFormat (value) {
  if (value == null) {
    return ''
  }

  var parts = []
  for (var i = 0, len = value.length; i < len; i += 4) {
    parts.push(value.substring(i, i + 4))
  }
  if (parts.length) {
    return parts.join(' ')
  } else {
    return value
  }
}

function dateFormat (dateVal, timeVal, sec) {
  var d
  var t
  if (dateVal) {
    d = DateTime.fromISO(dateVal).toFormat('y-MM-dd')
  } else {
    return ''
  }

  if (timeVal) {
    t = DateTime.fromISO(timeVal).toFormat('HH:mm:' + sec)

    return d + ' ' + t
  } else {
    return d + ' 00:00:' + sec
  }
}

// Convert from backend datetime / date string format to DateTime object
function toDateTimeObj (datetimeStr) {
  return DateTime.fromFormat(datetimeStr, 'dd/MM/yyyy HH:mm:ss')
}

function filterToDateTimeObj (datetimeStr) {
  return DateTime.fromFormat(datetimeStr, 'yyyy-MM-dd HH:mm:ss')
}

function capitalize (value) {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
}

function getHostType (type) {
  return type === '00' ? 'Switch'
    : type === '01' ? 'DS'
     : type === '02'? 'Dev Portal'
     : type === '03'? 'SSO': 'Unknown'
}

function getHostTypeOption () {
  return [
    { label: 'Switch', value: '00' },
    { label: 'DS', value: '01' },
    { label: 'Dev Portal', value: '02' },
    { label: 'SSO', value: '03' }
  ]
}

function getUserType (type) {
  return type === '00' ? 'Paynet Internal User'
    : type === '01' ? 'Issuer User'
      : type === '02' ? 'Token Requestor User' : 'Unknown'
}

function getTestCaseStatus (value) {
  return value === '00' ? 'Open'
    : value === '01' ? 'Passed'
      : value === '02' ? 'Failed'
        : value === '03' ? 'Pending' : 'Unknown'
}

function getTestCaseColor (value) {
  return value === '00' ? 'secondary'
    : value === '01' ? 'success'
      : value === '02' ? 'danger'
        : value === '03' ? 'dark' : 'danger'
}

function getUserTypeOption () {
  return [
    { label: 'Paynet Internal User', value: '00' },
    { label: 'Issuer User', value: '01' },
    { label: 'Token Requestor User', value: '02' }
  ]
}

function numberWithCommas (x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

function getCardSchemeLabel (value) {
  return value === 'MCCS' ? 'MyDebit'
    : value === 'VISA' ? 'Visa'
      : value === 'MC' ? 'Mastercard' : 'Unknown'
}

function getApiKeyTypeName (value) {
  switch (value) {
    case '00': return 'Authentication';
    case '01': return 'Notification';
    case '02': return 'Migration';
    case '10': return 'Device Token Authentication';
    case '11': return 'Device Token Webhook';
    default: return 'Unknown';
  }
}

function getTokenRequestorTypeOption () {
  return [
    { label: 'Payment Gateway', value: '00' },
    { label: 'Merchant', value: '01' },
    { label: 'Wallet Provider', value: '02' }
  ]
}

function checkValueIsTrue (val){
  if (val == '1' || val == 'true' || val == true) {
    return true;
  } else {
    return false;
  }
}
export default utils
